<template>
    <div>
        <v-dialog v-model="discardDialog" persistent max-width="602px" height="324px" @keydown="handleKeyDown">
            <v-card>
                <v-divider />
                <v-card-actions class="d-flex justify-center align-items-center flex-column" style="height:226px ">
                    <div class="ma-2" style="width: 336px; font-size: 24px; font-weight: 500; font-style: normal; color: #000000; line-height: 29.05px;width: 300px; word-wrap: break-word;">
                        Are you sure you want to discard the changes made ?
                    </div>
                    <div class="d-flex justify-center align-items-center ma-2" >
                        <div class="mr-8">
                            <v-btn
                            outlined
                            style="background-color: #ffffff; color: #EB5757; border: 1px solid #EB5757; width: 160px;"
                            @click="clickNo()"
                            >Cancel
                            </v-btn>
                        </div>
                        <div class="ml-8">
                            <v-btn style="background-color: #EB5757; color: #ffffff; width: 160px;" @click="clickYes()">
                                Yes, Discard
                            </v-btn>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
  </div>
</template>
  
<script>
import axios from "axios";

export default {
    props: ["displayDiscardDialogProps"],
    components: {},
    data() {
        return {
            trashIcon: 'trashIcon.svg',
            cloudFront: process.env.VUE_APP_CLOUDFRONT_URL_FOR_IMAGES,
            discardDialog: false,
        };
    },
    methods: {
        handleKeyDown(event) {
            if (event.keyCode === 27) {
                this.clickNo();
            }
        },
        clickYes() {
            this.discardDialog = false;
            this.$emit("closeDiscardDialog", {
                value: true
            });
        },
        clickNo() {
            this.discardDialog = false;
            this.$emit("closeDiscardDialog", {
                value: false
            });
        },
    },
    mounted() {
        // this.trashIcon = this.cloudFront + 'trashIcon.svg';
        this.discardDialog = this.$props.displayDiscardDialogProps;
    },
};
</script>