<template>
    <v-app>
            <div class="mb-4" style="
            display: flex;
            justify-content: center;
            align-items: center;
            width: 96px;
            height: 32px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            border-radius: 4px;
            word-break:normal;
            color: #AB6BFB;
            background: #DAEEE8;">
                Articles
            </div>
            <div style="text-align: left;"> 
                <div class="mb-4 d-flex justify-space-between">
                    <div style="width: 49%">
                        <div class="mb-3">
                            <span>Add Title</span>
                            <span style="color: #BDBDBD;">(English)</span>
                        </div>
                        <div 
                        style="display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        border-radius: 8px;
                        flex: none;
                        order: 1;
                        align-self: stretch;
                        flex-grow: 0;">
                            <v-text-field 
                            :rules="!editArticleFlagProps ? [noSameArticleName(articleTitleEN)]: []"
                            v-model="articleTitleEN"
                            filled placeholder="Promotional Article"></v-text-field>
                        </div>
                    </div>
                    <div style="width: 49%">
                        <div class="mb-3">
                            <span>Add Title</span>
                            <span style="color: #EB5757;">(Burmese)</span>
                        </div>
                        <div style="display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        border-radius: 8px;
                        flex: none;
                        order: 1;
                        align-self: stretch;
                        flex-grow: 0;">
                            <v-text-field
                            v-model="articleTitleMM" filled placeholder="Promotional Article"></v-text-field>
                        </div>
                    </div>
                </div>
                <div class="mb-4 d-flex justify-space-between">
                    <div style="width: 49%">
                        <div class="mb-3">
                            Add Description
                            <span>
                                (Optional)
                            </span>
                            <span style="color: #BDBDBD;">(English)</span>
                        </div>
                        <div style="display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        border-radius: 8px;
                        flex: none;
                        order: 1;
                        align-self: stretch;
                        flex-grow: 0;">
                            <v-text-field v-model="articleDescEN" filled placeholder="Promotional Article"></v-text-field>
                        </div>
                    </div>
                    <div style="width: 49%">
                        <div class="mb-3">
                            Add Description
                            <span>
                                (Optional)
                            </span>
                            <span style="color: #EB5757;">(Burmese)</span>
                        </div>
                        <div style="display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        border-radius: 8px;
                        flex: none;
                        order: 1;
                        align-self: stretch;
                        flex-grow: 0;">
                            <v-text-field v-model="articleDescMM" filled placeholder="Promotional Article"></v-text-field>
                        </div>
                    </div>
                </div>
                <div class="mb-4 d-flex flex-column">
                    <div class="mr-2" style="margin-top:auto;margin-bottom:auto;"> 
                        <span>Add Background Color</span>
                    </div>
                    <div class="d-flex" style="margin-top:auto;margin-bottom:auto;">
                        <div class="colorClass mr-4" :class="blueColorFlag ? ' colorClassSelected' : 'colorClassNotSelected'" @click="selectBackGroundColor('blue')">
                            <span style="width:48px; height: 48px;border-radius: 4px; background: #EAF4FF"></span>
                                Blue
                            <img :src="tickBlue" v-if="blueColorFlag"/>
                        </div>
                        <div class="colorClass mr-4" :class="greyColorFlag ? 'colorClassSelected' : 'colorClassNotSelected'" @click="selectBackGroundColor('grey')">
                            <span style="width:48px; height: 48px;border-radius: 4px; background: #EDEDED"></span>
                                Grey
                            <img :src="tickBlue" v-if="greyColorFlag"/>
                        </div>
                        <div class="colorClass mr-4" :class="whiteColorFlag ? 'colorClassSelected' : 'colorClassNotSelected'" @click="selectBackGroundColor('white')">
                            <span style="width:48px; height: 48px; border: 1px solid #E0E0E0; border-radius: 4px;background: #FFFFFF"></span>
                                White
                            <img :src="tickBlue" v-if="whiteColorFlag"/>    
                        </div>
                    </div>
                </div>
            </div>
            <div class="my-4">
                <div class="mb-3" style="text-align: left;">Add Icon</div>
                <div 
                style="
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    padding: 16px;
                    gap: 16px;
                    background: #F4F6FA;
                    border-radius: 6px;"
                >
                    <!-- height: 112px;
                    flex: none;
                    order: 1;
                    align-self: stretch;
                    flex-grow: 0; -->
                    <div @click="toggleImageFlag(icon, index)" class="iconDivClass d-flex mx-2" v-for="(icon, index) of selectIcons" :key="index">
                        <!-- :class="icon.showImage ? 'iconDivClassWidth120' : 'iconDivClassWidth80'"  -->
                        <img :src="icon.url" />
                        <img class="my-auto" v-if="icon.showImage" :src="tickBlue" />
                    </div>
                </div>
            </div>
            <div class="my-4" style="display: flex; flex-direction: column; align-items: flex-start;">
                <div v-if="!articleTabList.length == 0" class="my-4 d-flex justify-space-between align-items-center" style="width: 100%">
                    <div>Existing Article</div>
                </div>
                <div class="d-flex flex-row my-2" v-for="(article, index) of articleTabList"  :key="index" style="width:100%">
                    <div class="showCollasibleBanner d-flex flex-row" v-if="collapsibleBannerFlag">
                        <div class="collapsibleBannerNameClass">
                            Article {{ String(index + 1) }}
                        </div>
                        <div class="">
                            <span class="collapsibleBannerKeyClass">
                                Article Name:
                            </span>
                            <span class="collapsibleBannerDetailsClass">
                                {{ article.articleName }}
                            </span>
                        </div>
                        <div class="">
                            <span class="collapsibleBannerKeyClass">
                                Article Link:
                            </span>
                            <span class="collapsibleBannerDetailsClass">
                                {{ article.articleLink }}
                            </span>
                        </div>
                        <div class="d-flex ml-2">
                            <img class="mr-2" :src="editIcon" @click="openEditArticleDetails(article.id)"/>
                            <img class="mr-2" :src="deleteIcon" @click="deleteArticleDetails(article.id)"/>
                        </div>
                    </div>
                </div>
                <div class="my-4 d-flex justify-space-between align-items-center" style="width: 100%">
                    <div>Add Article</div>
                </div>
                <div v-if="articleTabList.length < 4" class="d-flex flex-column" style="width: 100%; border: 1px solid #E0E0E0;border-radius: 8px;" >
                    <div class="d-flex my-2 mx-4" style="justify-content: space-between; align-items: center;">
                        <div 
                        style="display: flex;
                        width:49%;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        border-radius: 8px;
                        flex: none;
                        order: 1;
                        align-self: stretch;
                        flex-grow: 0;">
                            <v-text-field 
                            filled 
                            placeholder="Article Name"
                            v-model="articleName"
                            required
                            />
                        </div>
                        <div class="d-flex my-1" style="width:49%;">
                            <div class="d-flex flex-column " style="width:100%; justify-content: space-evenly;">
                                <v-text-field 
                                filled
                                placeholder="Article Link"
                                v-model="articleLink"
                                required
                                :error-messages="hintText"
                                @input="validateURL" />
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-3 mx-4 flex-column" style="border: 1px solid #E0E0E0;border-radius: 8px; width: 98%;" 
                    :class="previewArticleImageURL ? 'height200' : 'height80'">
                        <div class="d-flex justify-space-between; align-items-center" style="width:100%;"> 
                            <div class="mtba ml-2" style="width:49.5%; text-align:left">
                                Article Image
                            </div>
                            <div class="d-flex my-2" style="width:49.5%; justify-content: end; align-items: center;">
                                <div v-if="showDesktopUploadButton" class="uploadButtonStyle" style="width:20%; height: fit-content;" @click="onDesktopArticleClick">
                                    <img class="mtba" :src="uploadArticleIcon" />
                                    <p class="mtba">Upload</p> 
                                    <input
                                        ref="uploader"
                                        class="d-none"
                                        type="file"
                                        accept="image/*"
                                        @change="onDesktopArticleChanged"
                                    >
                                </div>
                                <div v-else class="removeButtonStyle" style="width:20%; height: fit-content;" @click="removeDesktopArticle">
                                    <p class="mtba">Remove</p> 
                                    <input
                                        ref="uploader"
                                        class="d-none"
                                        type="file"
                                        accept="image/*"
                                        @change="onDesktopArticleChanged"
                                    >
                                </div>
                            </div>
                        </div>
                        <div v-if="previewArticleImageURL" style="width: 100%; height: 124.37px;">
                            <div style="width: 100%; text-align:center;background: #F4F6FA;">
                                <img class="mtb20" style="width: 150px; height: 84.37px; " :src="previewArticleImageURL" alt="Preview Image" />
                            </div>
                        </div>
                    </div>
                </div>
                <div 
                v-if="articleTabList.length < 4"
                :class="{'enabledDiv': allFieldsFilled}"
                class="d-flex justify-space-between align-items-center my-2"
                @click="addMultipleArticle()"
                >
                    <div>
                        <img :src="multipleAdds"/>
                    </div>
                    <div>
                        <span style="color: #FFFFFF">
                            Save & Add More
                        </span>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column justify-space-around" style="width: 100%;"> 
                <div class="d-flex flex-row" style="text-align: left">
                    <div class="mr-2" style="margin-top:auto;margin-bottom:auto;"> 
                        <span>Display Button</span>
                        <span>(Optional)</span>
                    </div>
                    <div style="margin-top:auto;margin-bottom:auto;"> 
                        <v-radio-group row v-model="displayJumpLinkFlag" @change="displayJumpLink()">
                            <v-radio label="Yes" value="Yes"></v-radio>
                            <v-radio label="No" value="No"></v-radio>
                        </v-radio-group>
                    </div>
                </div>
                <div 
                v-if="displayJumpLinkFlag === 'Yes'" 
                class="d-flex flex-column" 
                style="width: 100%; border: 1px solid #E0E0E0;border-radius: 8px;">
                    <div class="d-flex my-2 mx-4" style="justify-content: space-between;">
                        <div style="width: 49%">
                            <div style="text-align: left">
                                <span>Button Title</span>
                                <span>(English)</span>
                            </div>
                            <div style="display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            border-radius: 8px;
                            flex: none;
                            order: 1;
                            align-self: stretch;
                            flex-grow: 0;">
                                <v-text-field 
                                v-model="buttonTitleEN"
                                filled placeholder="Promotional Article"></v-text-field>
                            </div>
                        </div>
                        <div style="width: 49%">
                            <div style="text-align: left">
                                <span>Button Title</span>
                                <span style="color: #EB5757;">(Burmese)</span>
                            </div>
                            <div style="display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            border-radius: 8px;
                            flex: none;
                            order: 1;
                            align-self: stretch;
                            flex-grow: 0;">
                                <v-text-field 
                                v-model="buttonTitleMM"
                                filled placeholder="Promotional Article"></v-text-field>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex my-2 mx-4" style="justify-content: space-between;">
                        <div class="d-flex flex-column " style="width:49%; justify-content: space-evenly;">
                            <div class="" style="text-align: left;">
                                <span>Jump Link</span>
                            </div>
                            <div class=""> 
                                <v-autocomplete 
                                filled
                                v-model="jumpLink"
                                :items="routeNames"
                                required
                                @change="jumpLinkChanged()"
                                />
                            </div>
                        </div>
                        <div class="d-flex flex-column " style="width:49%; justify-content: space-evenly;" v-if="jumpLink == 'SpecializationsPage'">
                            <div class="mtba" style="text-align: left;">
                                <span v-if="jumpLink === 'SpecializationsPage'">Specialization Name</span>
                            </div>
                            <div class="mtba"> 
                                <v-autocomplete 
                                v-if="jumpLink === 'SpecializationsPage'"
                                item-text="name" 
                                item-value="_id" 
                                v-model="customURL" 
                                :items="customURLNames"
                                return-object
                                @change="customURLSelected()"
                                :disabled="jumpLink === 'SpecializationsPage' ? false : true"
                                filled  />
                            </div>
                        </div>
                        <div class="d-flex flex-row " style="width:49%; justify-content: space-evenly;" v-if="jumpLink == 'CatalogPage'">
                            <div class="mtba" style="text-align: left;">
                                <span v-if="jumpLink === 'CatalogPage'">Catalog Type</span>
                                <v-autocomplete 
                                v-if="jumpLink === 'CatalogPage'"
                                item-text="name" 
                                item-value="_id" 
                                v-model="catalogPageType" 
                                :items="CatalogPageTypes"
                                return-object
                                @change="customURLSelected()"
                                filled  />
                            </div>
                            <div v-if="jumpLink == 'CatalogPage'" class="mtba" style="text-align: left;">
                                <span v-if="catalogPageType == 'COLLECTIONS'">Collection Name</span>
                                <span v-if="catalogPageType == 'CATEGORIES'">Category Name</span>
                                <v-autocomplete 
                                v-if="jumpLink == 'CatalogPage' && catalogPageType == 'COLLECTIONS'||catalogPageType == 'CATEGORIES'"
                                item-text="cat_name" 
                                item-value="cat_id" 
                                v-model="customURL" 
                                :items="customURLNames"
                                return-object
                                @change="customURLSelected()"
                                filled />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row my-2" style="width:100%; justify-content: space-between;"
            :class="activeStatus ? 'borderBlue': 'discardBorder'">
                        <div 
                        class="mtba mx-3"
                        :class="activeStatus ? 'colorBlue' : 'discardButtonFont'" 
                        style="font-family: 'Inter';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 19px;
                                height: 19px;
                                flex: none;
                                order: 0;
                                flex-grow: 0;"> 
                            <span>Active Status: </span>
                            <span>{{ activeStatus ? 'Active' : 'InActive' }}</span>
                        </div>
                        <div class="mtba"> 
                            <v-switch
                            v-model="activeStatus" 
                            required
                            />
                        </div>
            </div>
            <div> 
                <div class="d-flex flex-row mtba" style="width:100%; height: 48px; justify-content: space-between;">
                </div>
                <div class="d-flex flex-row mtba" style="width:100%; height: 48px; justify-content: end;">
                    <v-btn class="mx-3 saveDiscardButton discardButtonFont" color="#FFFFFF" @click="discardAddArticleDialog()" style="background-color: #FFFFFF;">Discard</v-btn>
                    <v-btn :disabled="isDisabled" class="mx-3 saveDiscardButton saveButtonFont" color="#219653" @click="saveArticleDetails()" style="background-color: #219653;">Save</v-btn>
                </div>
            </div>
            <div v-if="displayDiscardDialog">
                <DiscardDialog
                    :displayDiscardDialogProps="displayDiscardDialog"
                    @closeDiscardDialog="closeDiscardDialog"
                ></DiscardDialog>
            </div>
    </v-app>
</template>

<script>
import axios from 'axios';
import { axios_auth_instance_epharmacy_admin } from '../utils/axios_utils';
import router from '@/router';
import draggable from 'vuedraggable';
import DiscardDialog from '../components/DiscardConfirmationDialog.vue';
export default {
    name: 'AddArticleComponent',
    props:['editArticleFlagProps', 'editArticleDetails', 'iconObjects', 'orderNumber', 'promoArticleNameList', 'pageType'],
    components: {
        'draggable': draggable,
        'DiscardDialog': DiscardDialog,
    },
    computed: {
        allFieldsFilled() {
            return this.articleName && this.articleLink && this.articleImageURL;
        },
        noSameArticleName(value) {
			return (value) => {
                let returnFlag =  false;
                if(this.promoArticleNameList && this.promoArticleNameList.includes(value)) {
                    returnFlag = true;
                }
                if(returnFlag) return "Article Segment Name Already Exists";
                if (!value.trim()) return "Give a valid article name";
                else return true;
            }
		},
        isDisabled() {
            return !(this.articleTitleEN && this.articleTitleMM && this.articleDescEN && this.articleDescMM && this.displayJumpLinkFlag 
            && (this.activeStatus == false || this.activeStatus == true) 
            && (this.displayJumpLinkFlag == 'Yes' ? this.buttonTitleEN && this.buttonTitleMM && this.jumpLink: true) 
            && (this.jumpLink == 'SpecializationsPage' ? this.customURL: true) 
            && (this.jumpLink == 'CatalogPage' ? this.catalogPageType: true) 
            && ((this.catalogPageType == 'COLLECTIONS' || this.catalogPageType == 'CATEGORIES') ? this.customURL: true) 
            && (this.articleTabList.length > 0) && (this.selectedIconIndex || parseInt(this.selectedIconIndex) == 0) 
            && this.backgroundColor);
        },
	},
    data() {
        return {
            articleImageSizeWidth: 356,
            articleImageSizeHeight: 200,
            articleLink: '',
            articleName:'',
            articleImageURL: '',
            collapsibleBannerFlag: false,
            articleData: [],
            displayDiscardDialog: false,
            uploadArticleIcon: 'uploadBannerIcon.svg',
            showDesktopUploadButton: true,
            previewArticleImageURL: null,
            catalogPageType: '',
            CatalogPageTypes: ["COLLECTIONS", "CATEGORIES"],
            customURL: '',
            customURLNames: [],
            customURLFlag: false,
            articleTabList: [],
            cloudFront: process.env.VUE_APP_CLOUDFRONT_URL_FOR_IMAGES,
            tickBlue: 'tickBlue.svg',
            remove: 'remove.svg',
            multipleAdds: 'multipleAdds.svg',
            editIcon: 'editIcon.svg',
            deleteIcon: 'deleteIcon.svg',
            articleTitleEN: '',
            articleTitleMM: '',
            articleDescEN: '',
            articleDescMM: '',
			jumpLink: '',
            routeNames: [],
            activeStatus: false,
            buttonTitleEN: '',
            buttonTitleMM: '',
            selectIcons: [],
            backgroundColor: '',
            selectedIconIndex: '',
            displayJumpLinkFlag: 'No',
            blueColorFlag: false,
            greyColorFlag: false,
            whiteColorFlag: false,
            displayJumpLinkDetails: {},
            hintText: '',
        };
    },
    watch: {
        previewArticleImageURL: async function(event64) {
            if(this.previewArticleImageURL && event64) {
                let fileName = `${String(Date.now())}_${this.articleImageName}`;
                let s3UploadData = { 
                    base64Str: this.previewArticleImageURL,
                    fileType: this.articleImageContentType,
                    fileName: fileName
                }
                try {
                    let s3UploadResponse = await axios_auth_instance_epharmacy_admin.post("/admin/bannerUploadIntoS3", s3UploadData);
                    if(s3UploadResponse && s3UploadResponse.data && s3UploadResponse.data.data) {
                        this.articleImageName = fileName;
                        this.articleImageURL = this.cloudFront + fileName;
                        this.showDesktopUploadButton = false;
                    } else {
                        this.showDesktopUploadButton = true;
                        this.previewArticleImageURL = null;
                        this.articleImageContentType = '';
                        this.articleImageName = '';
                    }
                } catch (error) {
                    if(error && error.response && error.response.status == 401) {
                        window.alert("Sorry !! You are unauthorized !!");
                        this.$cookies.remove('token');
                        this.$router.push({
                            name: "AdminLogin",
                        });
                    }
                }
            }
        },
    },
    methods: {
        async customURLSelected() {
            this.customURLFlag = true;
        },
        async jumpLinkChanged() {
            try {
                if (this.jumpLink === 'SpecializationsPage') {
                    this.customURLNames = [];
                    this.customURLFlag = true;
                    let readSuccessData = await axios.post(process.env.VUE_APP_BACKEND_URL + "/getSpecializationByPosition", {
                        token: this.$cookies.get("token"),
                        typeOfUser: "ADMIN"
                    });
                    if(readSuccessData && readSuccessData.data && readSuccessData.data.data) {
                        this.customURLNames = readSuccessData.data.data;
                    } else {
                        alert('Something went wrong');
                    }
                } else if (this.jumpLink === 'CatalogPage') {
                    this.customURLNames = [];
                    this.customURLFlag = true;
                    let readSuccessData = await axios_auth_instance_epharmacy_admin.post("/admin/getAllCategories", {});
                    if(readSuccessData && readSuccessData.data && readSuccessData.data.data && readSuccessData.data.data.Items && readSuccessData.data.data.Items.length > 0) {
                        this.customURLNames = readSuccessData.data.data.Items;
                    } else {
                        alert('Something went wrong');
                    }
                } else {
                    this.customURLFlag = false;
                    this.customURL = '';
                    this.catalogPageType = '';
                }
            } catch(error) {
                console.log('[ERROR] Error: ', error);
                alert('Something went wrong');
            }
        },
        validateURL() {
            const httpsPattern = /^(https?:\/\/)/;
            if(httpsPattern.test(this.articleLink)) {
                var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
                if(pattern.test(this.articleLink)) {
                    this.hintText = '';
                } else {
                    this.hintText = 'Enter a Valid URL';
                }
            } else {
                this.hintText = 'URL must start with https://';
            }
        },
        deleteArticleDetails(articleId) {
            for(let article of this.articleTabList) {
                if(articleId === article.id) {
                    // Replace 'objectToRemove' with the actual object you want to remove
                    var index = this.articleTabList.indexOf(article); 
                    if (index > -1) {
                        this.articleTabList.splice(index, 1);
                        this.clearArticleDetails();
                        this.collapseAddHeroBannerDiv = true;
                    }
                }
            }
        },
        openEditArticleDetails(articleId) {
            for(let article of this.articleTabList) {
                if(articleId === article.id) {
                    this.articleImageURL = article.articleImageURL;
                    this.previewArticleImageURL = article.articleImageURL;
                    this.articleLink = article.articleLink;
                    this.articleName = article.articleName;
                }
            }

            let index = this.articleTabList.findIndex(obj => obj.id === articleId);
            if (index !== -1) {
                this.articleTabList.splice(index, 1);
            }
        },
        addMultipleArticle() {
            if (this.previewArticleImageURL && this.articleLink && this.articleName) {
                var articleID = this.articleTabList && this.articleTabList.length ? this.articleTabList.length : 1;
                this.articleTabList.push({
                    id: articleID,
                    articleName: this.articleName,
                    articleImageURL: this.articleImageURL,
                    articleLink: this.articleLink
                })
    
                this.clearArticleDetails();
                this.collapsibleBannerFlag = true;
            }
        },
        clearArticleDetails() {
            this.$data.previewArticleImageURL = null;
            this.articleLink = '';
            this.articleName = '';
            this.articleImageURL= '';
            this.showDesktopUploadButton = true;
        },
        async removeDesktopArticle() {
            this.$data.previewArticleImageURL = null;
            this.showDesktopUploadButton = true;
        },
        closeDiscardDialog(value) {
            if(value.value) {
                this.$emit('discardAddArticleDialog', true);
            } else {
                this.displayDiscardDialog = false;
            }
        },
        onDesktopArticleClick() {
            this.isSelecting = true
            window.addEventListener('focus', () => {
                this.isSelecting = false
            });
            this.$refs.uploader.click();
        },
        onDesktopArticleChanged(e) {
            const file = e.target.files[0];
            this.articleImageName = file.name;
            this.articleImageContentType = file.type;
            if (file) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const image = new Image();
                    image.src = event.target.result;

                    image.onload = () => {
                        // Validate the image size
                        if(image.width == this.articleImageSizeWidth && image.height == this.articleImageSizeHeight) {
                            this.$data.previewArticleImageURL = event.target.result;
                        } else {
                            alert('Invalid Image  Size. Please Upload Image of Width: 356px  X  Height: 200px');
                            this.$data.previewArticleImageURL = null;
                            this.showDesktopUploadButton = true;
                        }
                    }
                };
                reader.readAsDataURL(file);
            } else {
                this.$data.previewArticleImageURL = null;
            }
            this.showDesktopUploadButton = false;
        },
        async saveArticleDetails() {
            if(this.displayJumpLinkFlag === 'Yes') {
                this.displayJumpLinkDetails = {
                    buttonTitleEN: this.buttonTitleEN,
                    buttonTitleMM: this.buttonTitleMM,
                    jumpLink: this.jumpLink,
                    customURLFlag: this.customURLFlag,
                    catalogPageType: this.jumpLink === 'CatalogPage' ? this.catalogPageType : '',
                    customURL: this.customURLFlag ? this.customURL : '',
                }
            }
            this.articleData = [];
            if(this.articleTabList.length <= 0) {
                if(this.articleImageURL && this.articleLink && this.articleName) {
                    this.articleData.push({
                        id: 1,
                        articleName: this.articleName,
                        articleImageURL: this.articleImageURL,
                        articleLink: this.articleLink
                    })
                }
            } else if(this.articleTabList.length > 0 && this.articleTabList.length <= 4) {
                for (let [index, article] of this.articleTabList.entries()) {
                    this.articleData.push({
                        id: index + 1,
                        articleName: article.articleName,
                        articleImageURL: article.articleImageURL,
                        articleLink: article.articleLink
                    })
                }

                if(this.articleImageURL && this.articleLink && this.articleName) {
                    this.articleData.push({
                        id: this.articleData.length + 1,
                        articleName: this.articleName,
                        articleImageURL: this.articleImageURL,
                        articleLink: this.articleLink
                    })
                }
            }
            
            var articleDataToBeSaved = {
                pageType: this.pageType,
                backgroundColor: this.backgroundColor,
                articleData: this.articleData,
                articleDescEN: this.articleDescEN,
                articleDescMM: this.articleDescMM,
                articleTitleEN: this.articleTitleEN,
                articleTitleMM: this.articleTitleMM,
                displayJumpLink: this.displayJumpLinkFlag === 'Yes' || this.displayJumpLinkFlag == 'Yes' ? true : false,
                displayJumpLinkDetails: this.displayJumpLinkFlag == 'Yes' ? this.displayJumpLinkDetails : {},
                activeStatus: this.activeStatus,
                selectedIconIndex: this.selectedIconIndex,
                selectedIconURL: this.selectedIconURL,
                segmentOrder: this.$props.orderNumber,
            };

            if(this.$props.editArticleFlagProps) {
                articleDataToBeSaved['shortID'] = this.shortID;
                try {
                    let updateArticleResponse = await axios_auth_instance_epharmacy_admin.post("/admin/updateArticle", articleDataToBeSaved);                
                    if(updateArticleResponse) {
                        this.$emit('updateArticleResponseEmit', true);
                    } else {
                        // this.$cookies.remove('token');
                        // this.$router.push({
                        //     name: "AdminLogin",
                        // });
                    }
                } catch (error) {
                    if(error && error.response && error.response.status == 401) {
                        window.alert("Sorry !! You are unauthorized !!");
                        this.$cookies.remove('token');
                        this.$router.push({
                            name: "AdminLogin",
                        });
                    }
                }
            } else {
                try {
                    let addArticleResponse = await axios_auth_instance_epharmacy_admin.post("/admin/addArticle", articleDataToBeSaved);                
                    if(addArticleResponse) {
                        this.$emit('addArticleResponseEmit', true);
                    } else {
                        // this.$cookies.remove('token');
                        // this.$router.push({
                        //     name: "AdminLogin",
                        // });
                    }
                } catch (error) {
                    if(error && error.response && error.response.status == 401) {
                        window.alert("Sorry !! You are unauthorized !!");
                        this.$cookies.remove('token');
                        this.$router.push({
                            name: "AdminLogin",
                        });
                    }
                }
            }
            
        },
        async selectBackGroundColor(ref) {
            this.backgroundColor = ref;
            if(ref === 'blue') {
                this.blueColorFlag = !this.blueColorFlag;
                this.greyColorFlag = false;
                this.whiteColorFlag = false;
            }
            if(ref === 'grey') {
                this.greyColorFlag = !this.greyColorFlag;
                this.blueColorFlag = false;
                this.whiteColorFlag = false;   
            }
            if(ref === 'white') {
                this.whiteColorFlag = !this.whiteColorFlag;
                this.blueColorFlag = false;
                this.greyColorFlag = false;
            }
        },
        displayJumpLink() {
            if(this.displayJumpLinkFlag == 'Yes') {
                this.displayJumpLinkDetails = {
                    buttonTitleEN: '',
                    buttonTitleMM: '',
                    jumpLink: '',
                };
            } else {
                this.displayJumpLinkDetails = {};
            }
        },
        async toggleImageFlag(icon, index) {
            for(let iconIndex in this.selectIcons) {
                if(parseInt(iconIndex) === index) {
                    this.selectIcons[iconIndex].showImage = !this.selectIcons[iconIndex].showImage;
                    if(this.selectIcons[iconIndex].showImage) {
                        this.selectedIconIndex = index;
                        this.selectedIconURL = this.selectIcons[iconIndex].url;
                    }
                } else {
                    this.selectIcons[iconIndex].showImage = false;
                }
            }
        },
        getRouteNames() {
            return router.options.routes
            .filter(route => route.visibility === true)
            .map(route => route.name);
        },
        discardAddArticleDialog() {
            this.displayDiscardDialog = true;
        },
        async getArticleItems() {
            var editArticleData = this.$props.editArticleDetails
            this.shortID = editArticleData.shortID;
            this.articleDescEN = editArticleData.articleDescEN;
            this.articleDescMM = editArticleData.articleDescMM;
            this.articleTitleEN = editArticleData.articleTitleEN;
            this.articleTitleMM = editArticleData.articleTitleMM;
            this.articleTabList = editArticleData.articleData;
            this.collapsibleBannerFlag = this.articleTabList ? true  : false;
            this.backgroundColor = editArticleData.backgroundColor;
            this.displayJumpLinkFlag = editArticleData.displayJumpLink ? "Yes" : "No";
            this.displayJumpLinkDetails = editArticleData.displayJumpLinkDetails;
            if(this.displayJumpLinkDetails) {
                this.buttonTitleEN = this.displayJumpLinkDetails.buttonTitleEN;
                this.buttonTitleMM = this.displayJumpLinkDetails.buttonTitleMM;
                this.jumpLink = this.displayJumpLinkDetails.jumpLink;
                if (this.jumpLink === 'SpecializationsPage') {
                    this.customURLFlag = true;
                    try {
                        let readSuccessData = await axios.post(process.env.VUE_APP_BACKEND_URL + "/getSpecializationByPosition", {
                            token: this.$cookies.get("token"),
                            typeOfUser: "ADMIN"
                        });
                        if(readSuccessData && readSuccessData.data && readSuccessData.data.data) {
                            this.customURLNames = readSuccessData.data.data;
                            this.customURLFlag = this.displayJumpLinkDetails.customURLFlag;
                            this.catalogPageType = '';
                            this.customURL = this.customURLFlag ? this.displayJumpLinkDetails.customURL : '';
                        } else {
                            alert('Something went wrong');
                        }
                    } catch (error) {
                        console.log('[ERROR] Error:', error);
                        alert('Something went wrong');
                    }
                } else if (this.jumpLink === 'CatalogPage') {
                    try {
                        this.customURLFlag = true;
                        let readSuccessData = await axios_auth_instance_epharmacy_admin.post("/admin/getAllCategories", {});
                        if(readSuccessData && readSuccessData.data && readSuccessData.data.data && readSuccessData.data.data.Items && readSuccessData.data.data.Items.length > 0) {
                            this.customURLNames = readSuccessData.data.data.Items;
                        } else {
                            alert('Something went wrong');
                        }
                        this.customURLFlag = this.displayJumpLinkDetails.customURLFlag;
                        this.catalogPageType = this.displayJumpLinkDetails.catalogPageType;
                        this.customURL = this.customURLFlag ? this.displayJumpLinkDetails.customURL : '';
                    } catch(error) {
                        console.log('[ERROR] Error:', error);
                        alert('Something went wrong');
                    }
                } else {
                    this.customURLFlag = false;
                    this.customURL = '';
                    this.catalogPageType = '';
                }
            }
            this.selectedIconIndex = editArticleData.selectedIconIndex;
            this.selectedIconURL = editArticleData.selectedIconURL;
            this.activeStatus = editArticleData.activeStatus;
            await this.selectBackGroundColor(editArticleData.backgroundColor);
            await this.toggleImageFlag(this.selectedIconURL, this.selectedIconIndex);
        }
    },
    async mounted() {
        this.uploadArticleIcon = this.cloudFront + this.uploadArticleIcon;
        this.editIcon = this.cloudFront + this.editIcon;
        this.deleteIcon = this.cloudFront + this.deleteIcon;
        this.multipleAdds = this.cloudFront + this.multipleAdds;
        this.mobileImage = this.cloudFront + this.mobileImage;
        this.remove = this.cloudFront + this.remove;
        this.tickBlue = this.cloudFront + this.tickBlue;
        this.selectIcons = [];
        for (let [index, icon] of this.$props.iconObjects.entries()) {
            this.selectIcons.push({
                id: index, 
                url: this.cloudFront + icon,
                showImage: false,
            });
        }
        this.routeNames = this.getRouteNames();
        if (this.$props.editArticleFlagProps) {
            await this.getArticleItems();
        } else {
            this.articleData = [];
            this.articleDescEN = '';
            this.articleDescMM = '';
            this.blockTitleMM = '';
            this.backgroundColor = '';
            this.selectedIconIndex = '';
            this.displayJumpLinkFlag = 'No';
            this.displayJumpLinkDetails = {};
            this.jumpLink = '';
            this.activeStatus = false;
            this.buttonTitleEN = '';
            this.buttonTitleMM = '';
            this.catalogPageType = '';
            this.customURLFlag = false;
            this.customURL = '';
            this.articleTabList = [];
            this.articleName = '';
            this.articleLink = '';
            this.articleImageURL = '';
        }
    },
  };
</script>

<style scoped>
.enabledDiv {
    padding: 10px 16px;
    gap: 10px;
    width: 200px;
    height: 42px;
    background: #48ACEF;
    border-radius: 4px;
    cursor: pointer;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.uploadButtonStyle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    color: #FFFFFF;
    background: #48ACEF;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.custom-append-icon .v-input__icon--append {
  background-image: url("https://s3iconimages.mymedicine.com.mm/calendar.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.articleBorder {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
}
.mtba {
    margin-top: auto;
    margin-bottom: auto;
}
.mlra {
    margin-left: auto;
    margin-right: auto;
}
.colorBlue {
    color: #48ACEF;
}
.uploadArticleButtonStyle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    width: 117px;
    height: 36px;
    color: #FFFFFF;
    background: #48ACEF;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.saveDiscardButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 16px;
    width: 176px;
    height: 48px;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.discardButtonFont {
    color: #828282;
}
.saveButtonFont {
    color: #FFFFFF;

}
.btnClass {
  background: transparent !important;
  color: rgb(228, 26, 26) !important;
  margin-right: -8%;
  font-size: 12pt !important;
}
.removeButtonStyle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    width: 112px;
    height: 36px;
    background: #EB5757;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.mtb20 {
    margin-top: 20px;
    margin-bottom: 20px;
}
.height80 {
    height: 70px;
}

.height200 {
    height: 196.38px;
}
.collapsible {
    cursor: pointer;
    padding: 18px;
    background-color: #f1f1f1;
    border: none;
    text-align: left;
    outline: none;
    width: 100%;
}

.content {
    padding: 0 18px;
    display: none;
    overflow: hidden;
    background-color: #ffffff;
}

.sinMulText {
    font-family: 'Inter';
    font-style: normal;
    line-height: 19px;
}
.sinMulCommon {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    justify-content: center;
    width: 189px;
    height: 48px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.sinMulSelected {
    font-size: 16px;
    font-weight: 600;
    border: 1px solid #48ACEF;
    color: #48ACEF;
}
.sinMulNotSelected {
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #E0E0E0;
    color: #333333;
}
.maxFont {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: right;
}
.height225 {
    height: 225px;
}
.height76 {
    height: 225px;
}
.iconDivClass {
    padding: 16px;
    gap: 16px;
    background: #FFFFFF;
    border: 1px dashed #BDBDBD;
    border-radius: 4px;
    /* height: 80px;
    flex: none;
    order: 1;
    flex-grow: 0; */
}
.iconDivClassWidth80 {
    width: 80px;
}
.iconDivClassWidth120 {
    width: 120px;
}
.inter400Normal {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
}
.colorClass {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    gap: 16px;
    width: 182px;
    height: 64px;
    border-radius: 4px;
    flex: none;
    background: #FFFFFF;
    order: 0;
    flex-grow: 0;
}
.colorClassSelected {
    border: 1px solid #48ACEF;
    color: #48ACEF;
}
.colorClassNotSelected {
    border: 1px solid #E0E0E0;
    color: #4F4F4F;
}
.discardBorder {
    border: 1px solid #828282; border-radius: 6px;
}
.borderBlue {
    border: 1px solid #48ACEF; border-radius: 6px;
}
.v-text-field__details {
min-height: 0px !important
}
.showCollasibleBanner {
    height: 64px;
    width: 100%;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}
.collapsibleBannerNameClass {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}
.collapsibleBannerDetailsClass {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}
.collapsibleBannerKeyClass {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #828282;
}
</style>